import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider, App as AntApp } from 'antd';
// import i18n from 'i18next';

import App from './App';
// eslint-disable-next-line import/extensions
import awsExports from './aws-exports.js';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store';
import './i18n';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
// const locale = i18n.language;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 14,
            borderRadius: 14,
            fontFamily:
              "montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          },
        }}
      >
        <AntApp className="h-full max-h-screen">
          <App />
        </AntApp>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
