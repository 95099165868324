import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * The header component
 */
function HeaderComponent() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center pt-6">
      <Card className="m-6 m-auto rounded-3xl bg-primary">
        <Row className="h-full">
          <Col span={24} className="flex justify-center items-center h-full">
            <Typography.Text strong className="w-fit text-white text-2xl text-center">
              {t`Header.title`}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default HeaderComponent;
