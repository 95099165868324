import { Button } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

/**
 *
 * @param root0
 * @param root0.onClick
 * @param root0.disabled
 */
export default function CopyButton({
  disabled,
  onClick,
  save,
}: {
  disabled: boolean;
  onClick: () => void;
  save: boolean;
}) {
  const { t } = useTranslation();

  const [copiedState, setCopiedState] = useState(false);

  const copyButtonTitle: string = save
    ? t`Autosummary.copyButton.title.copyAndSave`
    : t`Autosummary.copyButton.title.copy`;

  return (
    <Button
      disabled={disabled}
      className="mt-4 w-fit"
      type="primary"
      icon={!copiedState ? <CopyOutlined /> : <CheckOutlined />}
      onClick={async () => {
        onClick();
        setCopiedState(true);
        setTimeout(() => {
          setCopiedState(false);
        }, 3000);
      }}
    >
      {!copiedState ? copyButtonTitle : t`Autosummary.copyButton.action.title`}
    </Button>
  );
}
