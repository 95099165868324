import { CheckOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { Button, Rate } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PreviousButton from 'src/components/PreviousButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { rateSummary, selectStatusByLabel, Status } from 'src/state/events.state';

import './index.css';

/**
 *
 * @param root0
 */
export default function FeedbackForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [feedback, setFeedback] = useState<string>(null);
  const [rating, setRating] = useState<number>(5);

  const rateStatus = useAppSelector(state => selectStatusByLabel(state, 'rate'));

  const rateCallback = useCallback(async () => {
    await dispatch(rateSummary({ rating, feedback }));
  }, [rating, feedback, dispatch]);

  const desc = [
    t`Autosummary.feedback.desc.terrible`,
    t`Autosummary.feedback.desc.bad`,
    t`Autosummary.feedback.desc.acceptable`,
    t`Autosummary.feedback.desc.good`,
    t`Autosummary.feedback.desc.perfect`,
  ];

  if (rateStatus === Status.SUCCESS) {
    return (
      <div className="flex flex-col items-center">
        <p>{t`Autosummary.feedback.form.submitted`}</p>
        <PreviousButton />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <p className="m-0">{t`Autosummary.feedback.form.title`}</p>
      <Rate tooltips={desc} value={rating} allowClear={false} onChange={setRating} />
      {rating <= 4 && (
        <TextArea
          placeholder={t`Autosummary.feedback.form.comment`}
          className="w-64"
          onChange={({ target }) => setFeedback(target.value)}
          autoSize={{ minRows: 4, maxRows: 9999 }}
        />
      )}
      <div>
        <PreviousButton disabled={rateStatus === Status.PENDING} />
        <Button
          type="primary"
          className="ml-4"
          icon={<CheckOutlined />}
          onClick={rateCallback}
          loading={rateStatus === Status.PENDING}
        >
          {t`Autosummary.submitButton.title`}
        </Button>
      </div>
    </div>
  );
}
