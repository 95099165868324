import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'src/hooks/store';
import { actions } from 'src/state/events.state';

type Props = {
  disabled?: boolean;
};

/**
 *
 */
export default function PreviousButton({ disabled = false }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Button
      type="primary"
      disabled={disabled}
      icon={<ArrowLeftOutlined />}
      onClick={() => dispatch(actions.resetStatus('copy'))}
    >
      {t`Autosummary.previousButton.title`}
    </Button>
  );
}
