import { Amplify } from '@aws-amplify/core';
import { Layout } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import apis from './resources/calldesk-endpoints';

import HeaderComponent from './components/Header/Header.component';

import 'antd/dist/reset.css';
import AutoSummary from './pages/AutoSummary/AutoSummary.component';
import Home from './pages/Home';

/**
 *
 */
function endpointConfigs() {
  const constantCase = (x: string) => x.toUpperCase().replace(/ /g, '_');

  return Object.keys(apis).reduce((endpoints, name) => {
    const api = apis[name];
    const API_STAGE_PROP = `${constantCase(name)}_STAGE`;
    const stage = process.env[`REACT_APP_${API_STAGE_PROP}`] || api[`${API_STAGE_PROP}_PROD`];
    const protocol = stage.includes('local') ? 'http' : 'https';
    const endpoint = `${protocol}://${api[`URL_${stage.toUpperCase()}`]}`;

    return endpoint
      ? [
          ...endpoints,
          {
            name,
            endpoint,
            region: api.REGION,
          },
        ]
      : endpoints;
  }, []);
}

Amplify.configure({
  API: {
    endpoints: endpointConfigs(),
  },
});

const router = createBrowserRouter([
  {
    path: '/:sessionId',
    element: <AutoSummary />,
    errorElement: <Home />,
  },
]);

/**
 *
 */
function App() {
  return (
    <Layout className="h-full">
      <HeaderComponent />
      <Layout.Content className="max-h-screen flex flex-col">
        <RouterProvider router={router} />
      </Layout.Content>
      <Layout.Footer></Layout.Footer>
    </Layout>
  );
}

export default App;
