const config = {
  conversationsApi: {
    CONVERSATIONSAPI_STAGE_PROD: 'conversations-api',
    REGION: 'eu-west-1',
    URL_LOCAL: 'localhost:3010/local',
    URL_DEV: '8yx9ibmzg1.execute-api.eu-west-1.amazonaws.com/dev',
    URL_V0: 'jpar8lodai.execute-api.eu-west-1.amazonaws.com/v0',
    'URL_CONVERSATIONS-API': 'api.calldesk.fr/conversations-api-v0',
  },
};

export default config;
