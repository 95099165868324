import type { ResultStatusType } from 'antd/es/result';

import { Result, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CopyButton from 'src/components/CopyButton';
import FeedbackForm from 'src/components/FeedbackForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useEvents } from 'src/hooks/UseEvent';
import useCopyShortcut from 'src/hooks/UseCopyShortcut';
import {
  actions,
  Status,
  saveSummary,
  selectStatusByLabel,
  selectSummaryContent,
  validateSummary,
} from 'src/state/events.state';

import './index.css';

/**
 * AutoSummary component
 */
function AutoSummary() {
  const dispatch = useAppDispatch();
  const { sessionId } = useParams();
  const copyStatus = useAppSelector(state => selectStatusByLabel(state, 'copy'));
  const saveStatus = useAppSelector(state => selectStatusByLabel(state, 'save'));
  const validateStatus = useAppSelector(state => selectStatusByLabel(state, 'validate'));
  const summaryContentReceived = useAppSelector(selectSummaryContent);

  const { t } = useTranslation();

  const [summaryContent, setSummaryContent] = useState(summaryContentReceived);

  const loading = !summaryContentReceived;

  const saveCallback = useCallback(async () => {
    if (sessionId && summaryContent !== summaryContentReceived) {
      await dispatch(saveSummary({ content: summaryContent }));
    }
  }, [sessionId, summaryContent, summaryContentReceived, dispatch]);

  const validateCallback = useCallback(async () => {
    await dispatch(validateSummary());
  }, [dispatch]);

  const handleOnCopyButtonClick = async () => {
    await Promise.all([
      navigator.clipboard.writeText(summaryContent),
      validateCallback(),
      saveCallback(),
    ]);
    dispatch(actions.setStatus({ label: 'copy', status: Status.SUCCESS }));
  };

  useCopyShortcut(async () => {
    await handleOnCopyButtonClick();
  });

  useEvents(sessionId);

  useEffect(() => {
    setSummaryContent(summaryContentReceived);
  }, [summaryContentReceived]);

  if (copyStatus === Status.SUCCESS) {
    const resultStatus: ResultStatusType =
      saveStatus === Status.ERROR || validateStatus === Status.ERROR ? 'error' : 'success';

    return (
      <Result
        status={resultStatus}
        title={t(`Autosummary.feedback.${resultStatus}.title`)}
        subTitle={t(`Autosummary.feedback.${resultStatus}.subTitle`)}
      >
        <FeedbackForm />
      </Result>
    );
  }

  return (
    <Spin
      size="large"
      spinning={saveStatus === Status.PENDING || validateStatus === Status.PENDING}
    >
      <div className="AutoSummary p-8 h-full">
        <div className="flex flex-col items-center">
          <Spin
            tip={t`Autosummary.loading.title`}
            size="large"
            spinning={loading}
            wrapperClassName="flex w-full"
          >
            <TextArea
              className="flex-1 grow-1 w-full p-4"
              value={summaryContent}
              onChange={({ target }) => setSummaryContent(target.value)}
              placeholder={t`Autosummary.content.placeholder`}
              autoSize={{ minRows: 4, maxRows: 9999 }}
            />
          </Spin>
          <CopyButton
            disabled={loading}
            onClick={handleOnCopyButtonClick}
            save={summaryContent !== summaryContentReceived}
          />
        </div>
      </div>
    </Spin>
  );
}

export default AutoSummary;
