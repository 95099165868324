import { useEffect, useState } from 'react';

/**
 *
 * @param cb
 */
export default function useCopyShortcut(cb: () => Promise<void>) {
  const [state, setState] = useState('');
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === 'Control') setState('Control');

      if (event.key === 'c' && state === 'Control') {
        setState('CTRL+C');
        await cb();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [state, cb]);
}
