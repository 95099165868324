/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateSummary = /* GraphQL */ `subscription OnCreateSummary($PK: String) {
  onCreateSummary(PK: $PK) {
    PK
    account
    bot
    content
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSummarySubscriptionVariables,
  APITypes.OnCreateSummarySubscription
>;
