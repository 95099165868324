import { configureStore, PreloadedState, combineReducers } from '@reduxjs/toolkit';
import eventsReducer from './events.state';

const rootReducer = combineReducers({ events: eventsReducer });
// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof rootReducer>;

/**
 *
 * @param preloadedState
 */
function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

const store = setupStore();
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;
type AppStore = ReturnType<typeof setupStore>;

export { setupStore, store };
export type { RootState, AppDispatch, AppStore };
