import { type GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import { useEffect } from 'react';
import { Observable } from 'zen-observable-ts';

import { useAppDispatch } from './store';
import { actions } from '../state/events.state';
import * as subscriptions from '../graphql/subscriptions';
import { OnCreateSummarySubscription } from '../API';

/**
 *
 * @param sessionId
 */
export function useEvents(sessionId) {
  const dispatch = useAppDispatch();

  const updateSub = API.graphql<GraphQLSubscription<OnCreateSummarySubscription>>({
    query: subscriptions.onCreateSummary,
    variables: { PK: sessionId },
  }) as Observable<any>;

  useEffect(() => {
    const onCreateSummaryEventSubscription = updateSub.subscribe({
      next: ({
        value: {
          data: { onCreateSummary: summary },
        },
      }) => {
        dispatch(
          actions.addSummary({
            ...summary,
            receivedAt: Date.now(),
          }),
        );
      },
      error: console.error,
    });

    return () => {
      onCreateSummaryEventSubscription.unsubscribe();
    };
  }, []);
}
